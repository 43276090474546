import { Observable } from 'rxjs/Rx';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl + '/amenities';

@Injectable()
export class AmenitiesService {

  constructor(private http: HttpClient, private _http: Http,
    private authservce: AuthService) { }


  getContent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }
  // public  add(formData: any ) {
  //   let _url: string = API_URL+'/add';
  //   return this._http.post(_url, formData)
  //   .catch(this._errorHandler)
  //   .map(res => {
  //     return res.json();
  //   });
  // }


  add(page) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/add';
    const obj = {
      name: page.name,
    };
    return this._http.post(uri, obj, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  private _errorHandler(error: Response) {
    console.log("Error logged");
    return Observable.throw(error || 'Some Error on Server Occured');

  }

  edit(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  updatePage(formData: any, id) {
    const uri = API_URL + '/update/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .post(uri, formData, { headers: headers }).pipe(
        catchError(this._errorHandler),
        map((res: Response) => res.json()));
  }



  updatePageImage(formData: any, id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let _url: string = API_URL + '/updateImage/' + id;
    return this._http.post(_url, formData).pipe(
      catchError(this._errorHandler),
      map((res: Response) => res.json()));
  }

  delete(id) {
    const uri = API_URL + '/delete/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

}
