import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';



import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient, private _http: Http,
    private authservce: AuthService) { }

  downloadCSV(api, filterData){
    return this.postExcelFile(api, filterData).map(response => {
        return response;
    }).catch(error => {
      // if refresh token expiration error thn go to login page else just throw error
      if (error.status === 401 || error.status === 400) {
        var Tokenerror = error.error.error_description.split(': ');
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var refresh = currentUser.refresh_token
        if (Tokenerror[1] === refresh) {
              return Observable.of(false);
        } else {
            return Observable.throw(error);
        }

      } else {
          return Observable.throw(error);
      }
    })
  }

  postExcelFile(APIPart: string, filterData){
    var url =API_URL + APIPart;
  const body = {}
    let httpOptions = {
      headers: new Headers({ 'Content-Type': 'application/json','Authorization': this.authservce.getToken(),
        responseType:"text/html" as "json",
      })
    };
    return this._http.post(url, filterData, httpOptions)
  }

  downloadFile(data){
    data = data.replace(/#/g, ' ');
    
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'rides.csv';
    hiddenElement.click();
  }


  download(filterData) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/rides/download/list';
    return this._http.post(uri, filterData, { headers: headers }).pipe(
      map(res => {
        console.log(res);
        return res.json();
      }));
  }
}
