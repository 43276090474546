import { map , catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
import { Http, Headers } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { CouponDetails, CouponsParams } from 'app/shared/interfaces/coupon-details.model';
const API_URL = environment.apiUrl + '/coupon';

@Injectable()
export class CouponService {


  public couponDetails: CouponDetails = new CouponDetails();
  constructor(private http: HttpClient, private https: Http,
    private authservce: AuthService) { }

    getRegionContent(): Observable<any> {
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
      return this
        .http
        .get(environment.apiUrl +  '/region', options).pipe(
          map(res => {
            return res;
          }));
    }
    getCategoryContent(): Observable<any> {
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
      return this
        .http
        .get(environment.apiUrl +  '/category', options).pipe(
          map(res => {
            return res;
          }));
    }
    getUserPage(): Observable<any> {
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
      return this
        .http
        .get(environment.apiUrl +  '/user', options).pipe(
          map(res => {
            return res;
          }));
    }

    add(formData: any) {
      const uri = API_URL + '/add';
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      return this.https.post(uri, formData, { headers: headers }).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
    }

    private _errorHandler(error: Response) {
      console.error('Error Occured: ' + error);
      return Observable.throw(error || 'Some Error on Server Occured');

    }
    validateFile(name: String) {
      let ext = name.substring(name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() === 'png') {
        return true;
      } else if (ext.toLowerCase() === 'jpeg') {
        return true;
      } else if (ext.toLowerCase() === 'jpg') {
        return true;
      } else {
        return false;
      }
    }
    getCode(param ): Observable<any> {
      const options = {
        params: param,
        headers: this.authservce.setHeaders()
      };
      return this
        .http
        .get(API_URL, options).pipe(
          map(res => {
            return res;
          }));
    }

    getCouponDetails(id): Observable<any> {
      const options = {
        params: {},
        headers: this.authservce.setHeaders()
      };
      const uri = API_URL + '/edit/' + id;
      // const uri = API_URL
      return this
              .http
              .get(uri, options).pipe(
              map(res => {
                return res;
              }));
    }

    changeStatus(id, status) {

      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      let uri = API_URL + '/changeStatus/' + id + '?active=1';
      if (status) {
         uri = API_URL + '/changeStatus/' + id + '?active=0';
      }
      return  this.https.post(uri, id, { headers: headers }).pipe(
     map(res => {
      return res.json();
    }));
    }

    activeUser(id) {
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());
      const uri = API_URL + '/active/' + id;
      return  this.https.post(uri, id, { headers: headers }).pipe(
     map(res => {
      return res.json();
    }));
    }

    // setFormData() {
    //   const formData: FormData = new FormData();
    //   const keys = Object.keys(this.campaignDetails);
    //   for (const key of keys) {
    //     if (key === 'tags' ||  key === 'conversion' || key === 'apps'
    //             || key === 'whenjson'  || key === 'whomjson_ui' ||
    //               key === 'payloadarr' || key === 'layoutjson') {
    //       formData.append(key, JSON.stringify(this.campaignDetails[key]));
    //     } else
    //       formData.append(key, this.campaignDetails[key]);
    //   }
    //   return formData
    // }

    applyCoupon(formData: any) {
      const headers = new Headers();
      headers.append('Authorization', this.authservce.getToken());

      const uri = environment.apiUrl + '/api/applyCoupon';
      return this.https.post(uri, formData, { headers: headers }).pipe(
        catchError(this._errorHandler),
        map(res => {
          return res.json();
        }));
    }
}
