import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';



import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
const API_URL = environment.apiUrl + '/region';

@Injectable()
export class RegionService {
  regionData: any;


  constructor(private http: HttpClient,
    private _http: Http,
    private authservce: AuthService,
    private router: Router, ) { }

  getContent(): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    return this
      .http
      .get(API_URL, options).pipe(
        map(res => {
          return res;
        }));
  }


  add(page) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    const uri = API_URL + '/add';
    const obj = {
      name: page.name,
      night_start_time: page.night_start_time.hour +':' + page.night_start_time.minute ,
      night_end_time: page.night_end_time.hour + ':' + page.night_end_time.minute ,
      active: page.active,
      ride_search_radius_in_km: page.ride_search_radius_in_km
    };
    return this._http.post(uri, obj, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }

  edit(id) : Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = API_URL + '/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  update(page: any, id) {
    const uri = API_URL + '/update/' + id;
    const obj = {
      name: page.name,
      night_start_time: page.night_start_time.hour + ':' + page.night_start_time.minute ,
      night_end_time: page.night_end_time.hour + ':' + page.night_end_time.minute ,
      active: page.active,
      ride_search_radius_in_km: page.ride_search_radius_in_km,
      standard_deduction_amount: page.standard_deduction_amount
    };
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .post(uri, obj, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  delete(id) {
    const uri = API_URL + '/delete/' + id;
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    return this
      ._http
      .get(uri, { headers: headers }).pipe(
        map(res => {
          return res.json();
        }));
  }

  setRegionData (data) {
    localStorage.setItem('regionData', JSON.stringify(data));
    this.router.navigate(['admin/region/view-route']);
  }
  getRegionData() {
    return this.regionData = JSON.parse(localStorage.getItem('regionData'));
  }

  //  pickup and drop

  getPickupDropByRegion(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const url = environment.apiUrl + '/pickup-drop/' + id;
    console.log(url)
    return this
      .http
      .get(url, options).pipe(
        map(res => {
          return res;
        }));
  }

addPickupDrop(page, id) {
    const headers = new Headers();
    headers.append('Authorization', this.authservce.getToken());
    let uri = environment.apiUrl + '/pickup-drop/update/' + id;
    if (id === 'add') {
      uri = environment.apiUrl + '/pickup-drop/add';
    }
    page['night_start_time'] = page.night_start_time.hour + ':' + page.night_start_time.minute;
    page['night_end_time'] = page.night_end_time.hour + ':' + page.night_end_time.minute;
    console.log(page);
    // const obj = {
    //   name: page.name,
    //   night_start_time: page.night_start_time.hour + ':' + page.night_start_time.minute ,
    //   night_end_time: page.night_end_time.hour + ':' + page.night_end_time.minute ,
    //   active: page.active
    // };
    return this._http.post(uri, page, { headers: headers }).pipe(
      map(res => {
        return res.json();
      }));
  }
  editPickupDropRout (id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = environment.apiUrl + '/pickup-drop/edit/' + id;
    return this
      .http
      .get(uri, options).pipe(
        map(res => {
          return res;
        }));
  }

  getRouteDetailsInfo(id): Observable<any> {
    const options = {
      params: {},
      headers: this.authservce.setHeaders()
    };
    const uri = environment.apiUrl + '/info/' + id;
    return this
            .http
            .get(uri, options).pipe(
            map(res => {
              return res;
            }));
  }
}
